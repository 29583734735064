
import { Options, Vue } from 'vue-class-component';

import PAFForm from './components/PAFForm.vue';

@Options({
  components: {
    PAFForm,
  },
})
export default class SettingsPageForms extends Vue {

}
