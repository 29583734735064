
import { Options, Vue } from 'vue-class-component';
import requests from '@/requests';
import SimpleModal from '@/common/SimpleModal.vue';

import { SimpleModalButton } from '@/interface/other.interface';
import FormSlot from './FormSlot.vue';

@Options({
  components: {
    FormSlot,
    SimpleModal,
  },
})
export default class PAFForm extends Vue {
  public signed = false;

  public buttonsSimpleModal: SimpleModalButton[] = [
    { button: 'fill', title: 'OK', value: 'OK' },
  ];

  public status: 'Not Signed' | 'Valid' | 'Expired' = 'Not Signed';

  public modal = {
    success: false,
  }

  public loader = true;

  public sendPAFForm(): void {
    this.loader = true;
    requests.profile.sendPAFFormOnEmail()
      .then((res) => { this.modal.success = true; })
      .finally(() => { this.loader = false; });
  }

  public signIt(): void {
    this.loader = true;
    requests.profile.signItPAForm()
      .then(() => { this.modal.success = true; })
      .finally(() => { this.loader = false; });
  }

  private updateStatusPAFForm(status: boolean): void {
    const { profile } = this.$store.state;

    this.$store.commit('set', { key: 'profile', payload: { ...profile, has_paf_form: status } });
  }

  created(): void {
    requests.profile.getPAFForm()
      .then((res) => {
        const { data } = res;
        const currentDate = new Date();

        // TODO: add check date on expire

        if (data.valid) {
          this.status = 'Valid';
          this.signed = true;
        }

        this.updateStatusPAFForm(data.valid);
      })
      .catch(() => {
        this.updateStatusPAFForm(false);
      })
      .finally(() => {
        this.loader = false;
      });
  }
}
