<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M49.5174 13.0311C49.5174 23.7909 55.0909 31.1798 55.0909 36.3644C55.0909 41.5491 52.1105 52.438 28.2591 52.438C4.4078 52.438 0.90625 37.7893 0.90625 32.0867C0.90625 5.38244 49.5174 -6.02445 49.5174 13.0311V13.0311Z" fill="#F5F6FA"/>
    <path d="M37.1836 4.06885L38.2835 2.9689L40.4834 5.16879L39.3835 6.26874L37.1836 4.06885Z" fill="#646995"/>
    <path d="M43.5068 10.3936L44.6068 9.29361L46.8067 11.4935L45.7067 12.5934L43.5068 10.3936Z" fill="#646995"/>
    <path d="M43.2324 5.16895L45.4323 2.96906L46.5322 4.069L44.3324 6.26889L43.2324 5.16895Z" fill="#646995"/>
    <path d="M37.7216 45.5H18.2771C16.5582 45.5 15.166 44.1078 15.166 42.3889V13.6111C15.166 11.8922 16.5582 10.5 18.2771 10.5H37.7216C39.4404 10.5 40.8327 11.8922 40.8327 13.6111V42.3889C40.8327 44.1078 39.4404 45.5 37.7216 45.5Z" fill="#F3F7FC"/>
    <path d="M18.666 42.3889V13.6111C18.666 11.8922 20.0582 10.5 21.7771 10.5H18.2771C16.5582 10.5 15.166 11.8922 15.166 13.6111V42.3889C15.166 44.1078 16.5582 45.5 18.2771 45.5H21.7771C20.0582 45.5 18.666 44.1078 18.666 42.3889Z" fill="#C4D6F0"/>
    <path d="M37.7222 46.6663H18.2778C15.918 46.6663 14 44.7483 14 42.3886V13.6108C14 11.251 15.918 9.33301 18.2778 9.33301H37.7222C40.082 9.33301 42 11.251 42 13.6108V42.3886C42 44.7483 40.082 46.6663 37.7222 46.6663ZM18.2778 11.6663C17.206 11.6663 16.3333 12.539 16.3333 13.6108V42.3886C16.3333 43.4603 17.206 44.333 18.2778 44.333H37.7222C38.794 44.333 39.6667 43.4603 39.6667 42.3886V13.6108C39.6667 12.539 38.794 11.6663 37.7222 11.6663H18.2778Z" fill="#383B53"/>
    <path d="M19.834 29.5557H36.1673V31.889H19.834V29.5557Z" fill="#383B53"/>
    <path d="M19.834 35.7778H36.1673V38.1112H19.834V35.7778Z" fill="#383B53"/>
    <path d="M19.834 23.333H36.1673V25.6663H19.834V23.333Z" fill="#383B53"/>
    <path d="M19.834 17.1113H28.3895V19.4447H19.834V17.1113Z" fill="#383B53"/>
    <path d="M3.11133 44.333H52.8891V46.6663H3.11133V44.333Z" fill="#383B53"/>
  </svg>
  <!-- eslint-enable -->
</template>
