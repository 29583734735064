
import { Options, Vue } from 'vue-class-component';

import IconForm from '@/icons/other/Form.vue';
import IconCheck from '@/icons/service/Check.vue';

@Options({
  components: {
    IconForm,
    IconCheck,
  },
  props: {
    title: String,
    signed: Boolean,
    loader: Boolean,
  },
})
export default class FormSlot extends Vue {}
