import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b0b63e3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleModal = _resolveComponent("SimpleModal")!
  const _component_FormSlot = _resolveComponent("FormSlot")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.modal.success)
      ? (_openBlock(), _createBlock(_component_SimpleModal, {
          key: 0,
          title: "Success",
          subtitle: "Form was sent to your e-mail.",
          buttons: _ctx.buttonsSimpleModal,
          onAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modal.success = false)),
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modal.success = false))
        }, null, 8, ["buttons"]))
      : _createCommentVNode("", true),
    _createVNode(_component_FormSlot, {
      title: `PAF form (${_ctx.status})`,
      signed: _ctx.signed,
      loader: _ctx.loader
    }, {
      default: _withCtx(() => [
        (_ctx.signed)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              disabled: _ctx.loader,
              class: "btn border mini send-it-to-my-email",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.sendPAFForm && _ctx.sendPAFForm(...args)))
            }, " Send it to my email ", 8, _hoisted_1))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              disabled: _ctx.loader,
              class: "btn fill mini sign-it",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.signIt && _ctx.signIt(...args)))
            }, "Sign it", 8, _hoisted_2))
      ]),
      _: 1
    }, 8, ["title", "signed", "loader"])
  ], 64))
}